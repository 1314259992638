.quotation {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-box {
        background: $color--primary;
        padding: 64px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        // max-width: 963px;
        box-shadow: 0px 33px 62px rgba(129, 129, 129, 0.06), 0px 13.7866px 25.9021px rgba(129, 129, 129, 0.0431313),
            0px 7.37098px 13.8485px rgba(129, 129, 129, 0.0357664), 0px 4.13211px 7.76336px rgba(129, 129, 129, 0.03),
            0px 2.19453px 4.12306px rgba(129, 129, 129, 0.0242336), 0px 0.913195px 1.7157px rgba(129, 129, 129, 0.0168687);
        border-radius: 16px;
        @include media-breakpoint-down(md) {
            flex-direction: column;
            padding: 32px;
        }
    }
    &-content {
        padding: 24px;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        &__question {
            color: $txt-color-primary;
            line-height: 22px;
            margin-bottom: 8px;
        }
        &__industry {
            margin-bottom: 32px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            &-item {
                padding: 12px 16px !important;
                background: #f9f1e0;
                border-radius: 8px !important;
                margin-right: 16px;
                height: unset;
                border: unset !important;
                &::before {
                    display: none !important;
                }
                @include media-breakpoint-down(xs) {
                    margin-right: 8px;
                    margin-bottom: 8px;
                }
                &.ant-radio-button-wrapper-checked {
                    border: unset !important;
                    background-color: #f0daae;
                    &:focus-within {
                        box-shadow: unset !important;
                    }
                }
                & span:last-child {
                    color: #000;
                    @include media-breakpoint-down(xs) {
                        white-space: nowrap;
                    }
                    svg {
                        margin-right: 8px;
                    }
                }
            }
        }
        &__size {
            display: flex;
            justify-content: center;
            @include media-breakpoint-down(xs) {
                flex-direction: column;
            }
            .ant-form-item-label {
                padding: 0 0 4px;
                label {
                    color: #000;
                    font-weight: 400;
                }
            }
        }
        .ant-form-item-control-input,
        .ant-input-number {
            margin-right: 8px;
            input {
                padding: 5px 8px 5px 12px;
                background-color: $bg-color--white;
                border: 1px solid #c7c7c7;
                border-radius: 2px;
            }
        }
        .ant-input-number {
            border: unset;
        }
        &.left {
            margin-right: 64px;
            @include media-breakpoint-down(md) {
                padding: 0;
                margin-right: 0;
                margin-bottom: 32px;
            }
        }
        &.right {
            background-color: #f9f9fb;
            border-radius: 8px;
            height: 100%;
        }
        &__price {
            display: flex;
            justify-content: space-between;
            // gap: 160px;
            margin-bottom: 8px;
            & span:last-child {
                font-weight: 500;
            }
            &.first-row {
                p {
                    &:first-child {
                        margin: 24px 0;
                    }
                }
                span {
                    &:last-child {
                        display: flex;
                        align-items: center;
                    }
                }
            }
            > div {
                p {
                    font-size: 12px;
                    line-height: 20px;
                    color: #707070;
                }
            }
        }
        &__func {
            margin-top: 112px;
            @include media-breakpoint-down(md) {
                margin-top: 60px;
            }
            blockquote {
                font-style: italic;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                margin-bottom: 16px;
                span {
                    color: #fcb823;
                }
            }
            .btn {
                width: 100%;
                padding: 6px 0px;
                height: unset;
            }
        }
    }
    &-modal {
        & .ant-modal-content {
            padding: 23px 28px;
        }
        & .ant-modal-confirm-body .ant-modal-confirm-content {
            margin: unset;
        }
        &__body {
            max-height: 350px;
            padding: 0 2px;
            margin-top: 24px;
            margin-bottom: 36px;
            overflow: auto;

            &::-webkit-scrollbar {
                background: #fff;
                width: 6px;
            }
            &::-webkit-scrollbar-thumb {
                background: #c4c4c4;
                border-radius: 4px;
                width: 6px;
            }
            h3 {
                font-weight: 500;
                font-size: 16px;
                margin-bottom: 15px;
                margin-top: 24px;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        &__checkbox {
            display: flex;
            flex-direction: column;
            .ant-checkbox-wrapper {
                margin: unset;
                margin-bottom: 8px;
            }
            & .ant-checkbox-inner {
                box-shadow: 0px 0px 0px 2px #ddf0ff;
                border: 1px solid #fcb823;
            }
        }
        .ant-modal-header,
        .ant-modal-body {
            padding: 0;
            border: unset;
        }
        .ant-modal-footer {
            padding: 0;
            border: unset;

            .btn {
                width: 100%;
                &:focus {
                    background: $color-tenant--btn-active;
                    border-color: $color-tenant--btn-active;
                }
                &:hover {
                    background: $color-tenant--btn-active-hover;
                    border-color: $color-tenant--btn-active-hover;
                }
            }
        }
    }
}
