.import-detail-info {
    p {
        width: 40%;
    }
}
.form-table-price-product {
    .table-thead:first-child .ant-col:last-child {
        border-top-right-radius: 2px;
    }
    .table-thead > .ant-col,
    .table-tbody > .ant-col {
        border-right: 1px solid #f0f0f0;
        padding: 10px !important;
        border-bottom: 1px solid #f0f0f0;
    }
    .table-thead > .ant-col {
        font-weight: 500;
        text-align: left;
        background: #fafafa;
        transition: background 0.3s ease;
    }
    .table-tbody > .action {
        padding-left: 0 !important;
        padding-right: 0 !important;
        border-right: unset !important;
    }
}

.product-options {
    .option-values {
        display: flex;
        flex-wrap: wrap;
    }

    .ant-tag {
        background-color: $white-lilac;
        display: inline-flex;
        margin-right: 0;
        min-width: 155px;
        padding: 5px 8px 5px 10px;

        .ant-tag-close-icon {
            color: #d9dbe9;
            margin-left: auto;
        }
    }
}

.product-list-table {
    .warehouse_stocks {
        padding: 5px 0;
    }
}

.add-product-import-package {
    border-radius: 4px;
    border: 1px solid #d9dbe9;
    .ant-table-tbody > tr > td {
        border-bottom: unset !important;
    }
}

._packing-service-info .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
    padding: 10px 0;
}

.create-order-product-table .ant-table-footer {
    background-color: white;
    .ant-row {
        min-height: 32px;
    }
    .hidden_input {
        min-height: 0 !important;
    }
}
.custom-tab-product {
    .ant-tabs-nav {
        &::before {
            display: none;
        }
    }
    .ant-tabs-tab {
        background: #f9f9fb;
        cursor: pointer;
        border-radius: 8px;
        padding: 12px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #787a8e;
        margin-right: 8px;
        border: 1px solid #d9dbe9;
        &.ant-tabs-tab-active {
            background: $color-tenant--background;
            box-shadow: unset;
            color: #f9f9fb;
            border-color: $color-tenant--background;
            .ant-tabs-tab-btn {
                color: #f9f9fb;
            }
        }
    }
    .ant-tabs-ink-bar {
        background-color: transparent;
    }
    .ant-tabs-nav-list {
        margin-bottom: 4px;
    }
}
.product-list-edit-table {
    & .ant-table-tbody > tr > td {
        border-bottom: unset;
    }
}

.warehouse_stocks-total_prices {
    color: $color-tenant--btn-link;
    &:hover {
        color: $color-tenant--btn-link-hover;
    }
}

._quick-filter-btn {
    box-shadow: 0px 33px 62px rgba(129, 129, 129, 0.06), 0px 13.7866px 25.9021px rgba(129, 129, 129, 0.0431313),
        0px 7.37098px 13.8485px rgba(129, 129, 129, 0.0357664), 0px 4.13211px 7.76336px rgba(129, 129, 129, 0.03),
        0px 2.19453px 4.12306px rgba(129, 129, 129, 0.0242336), 0px 0.913195px 1.7157px rgba(129, 129, 129, 0.0168687);
    border-radius: 8px;
    border: unset;
    padding: 12px 16px;
    height: unset;
    font-weight: 500;
    @include media-breakpoint-down(xs) {
        margin-bottom: 0.5em;
    }
    &:focus {
        background: $color-tenant--btn-active-hover;
    }
}
.combo-tag {
    background: #e0f9f7;
    color: #09b2aa;
    padding: 0px 10px;
    border-radius: 4px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80px;
}
.add-sku-table-modal {
    .ant-table-body {
        padding: 6px;
        &::-webkit-scrollbar {
            background: #fff;
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background: #c4c4c4;
            border-radius: 4px;
            width: 8px;
        }
    }
}

.list-sku-skeleton {
    .ant-skeleton-title {
        margin-top: 0 !important;
    }
    .ant-skeleton-paragraph {
        margin-top: 12px !important;
        li {
            width: 100% !important;
        }
    }
}

.tab-list-product {
    // border-radius: 8px;
    height: unset;
    font-weight: 500;
    padding: 8px 12px;
    min-width: 100px;
    &:hover,
    &:focus {
        color: #000000d9;
        border-color: #d9d9d9;
        background: #dddada;
    }

    &.active {
        background: #dddada;
        // color: #fff;
        border-color: #dddada;
    }

    &-quantity {
        padding: 1px 8px;
        color: #fcb823;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        background: #f9f1e0;
        border-radius: 20px;
    }
}
