@import './../../../resources/scss/bootstrap/functions';
@import './../../../resources/scss/bootstrap/variables';
@import './../../../resources/scss/bootstrap/mixins/breakpoints';

.register {
    background-color: #f9f9fb;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    width: 100%;
    padding: 10px 10px;
    @media (max-height: 700px) {
        grid-template-rows: 70px calc(100% - 10px) !important;
    }
    @media (min-height: 700px) and (max-height: 740px) {
        grid-template-rows: 70px calc(100% - 5%) !important;
    }

    @media (min-height: 740px) and (max-height: 780px) {
        grid-template-rows: 70px calc(100% - 10%) !important;
    }
    @media (min-height: 780px) and (max-height: 900px) {
        grid-template-rows: 70px calc(100% - 10%) !important;
    }

    @include media-breakpoint-up(xl) {
        overflow: auto;
        padding: 10px 40px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 70px calc(100% - 17%);
        grid-template-areas:
            'header header'
            'logo      register';
    }

    .header {
        grid-area: header;
    }

    .register-logo {
        grid-area: logo;
        text-align: center;
        .ant-image-img {
            width: 90%;
        }
    }

    .form-register {
        background-color: #ffff !important;
        border-radius: 12px;
        padding: 24px;
        grid-area: register;
        box-shadow: 0px 33px 62px rgba(129, 129, 129, 0.06), 0px 13.7866px 25.9021px rgba(129, 129, 129, 0.0431313),
            0px 7.37098px 13.8485px rgba(129, 129, 129, 0.0357664), 0px 4.13211px 7.76336px rgba(129, 129, 129, 0.03),
            0px 2.19453px 4.12306px rgba(129, 129, 129, 0.0242336), 0px 0.913195px 1.7157px rgba(129, 129, 129, 0.0168687);

        .register-btn-submit {
            box-shadow: 0px 17px 79px rgba(0, 0, 0, 0.05), 0px 7.10219px 33.0043px rgba(0, 0, 0, 0.0359427),
                0px 3.79717px 17.6457px rgba(0, 0, 0, 0.0298054), 0px 2.12866px 9.89203px rgba(0, 0, 0, 0.025),
                0px 1.13052px 5.25358px rgba(0, 0, 0, 0.0201946), 0px 0.470434px 2.18613px rgba(0, 0, 0, 0.0140573);
        }

        & .ant-row {
            &:last-child {
                margin-bottom: 0;
            }
        }

        & .ant-input-group-addon {
            background-color: #fff;
        }
    }

    .footer {
        grid-area: footer;
    }

    &-select-prefix {
        .ant-select-selector {
            position: relative;
        }
        .ant-select-selection-item {
            display: flex;
            align-items: center;
            position: absolute !important;
        }
    }
}

.register-select-content {
   .ant-select-item-option-content{
    display: flex;
    align-items: center;
   }
}
