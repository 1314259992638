._onboard-content-header {
    height: 80px;
}
.dashboard-disable {
    background: $txt-color--white;
    opacity: 0.5;
}
.dashboard-active {
    transform: scaleY(1.1);
    transform-origin: bottom;
    box-shadow: 0px 17px 79px rgba(0, 0, 0, 0.05), 0px 7.10219px 33.0043px rgba(0, 0, 0, 0.0359427),
        0px 3.79717px 17.6457px rgba(0, 0, 0, 0.0298054), 0px 2.12866px 9.89203px rgba(0, 0, 0, 0.025),
        0px 1.13052px 5.25358px rgba(0, 0, 0, 0.0201946), 0px 0.470434px 2.18613px rgba(0, 0, 0, 0.0140573);
}

.dashboard-step {
    border-radius: 8px;
}

.canvas-dashboard {
    height: 60vh;
}
._dashboard-content {
    & .finance_order {
        flex-wrap: wrap;
        justify-content: unset;
        .dashboard-box {
            @include media-breakpoint-up(xl) {
                flex:1 0 20%;
                // flex-grow: 1;
                // flex-basis: 20%;
                // width: 20%;
            }
        }
    }
}
.dashboard-box {
    @include media-breakpoint-down(xs) {
        & .dashboard_stats {
            justify-content: space-around;
            padding: 14px;
            & > .stats_amount {
                margin-top: 0 !important;
            }

            & > div {
                font-size: 9px;
                svg {
                    width: 9px;
                    height: 9px;
                }
            }
        }
    }
    .dashboard-director {
        white-space: nowrap;
    }
}
