.upload-list-item {
    align-items: center;
    background-color: #eaf6f4;
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;

    .anticon-file-excel {
        color: #06c675;
    }

    .ant-typography {
        margin-left: 4px;
    }
}

._span--delete {
    background: #e0e0e0;
    color: $txt-color--white;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    width: 20px;
    height: 20px;
    font-size: 10px;
    border-radius: 100%;
    &:hover {
        background: $color--secondary;
        color: #4551bb;
    }
}
.import-by-purchase-order-table {
    .ant-table-title {
        padding-top: unset;
        padding-bottom: unset;
    }
}
.number-circle {
    min-width: 20px;
    //height: 20px;
    line-height: 20px;
    border-radius: 20px;
    margin: 0px 4px;
    padding: 0 5px;
    background: $color-tenant--background;
    color: $color--primary;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    font-size: 12px;
    white-space: nowrap;
    text-align: center;
}
._span--add {
    background: $color--secondary;
    color: $color--primary;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    width: 20px;
    height: inherit;
    font-size: 10px;
    border-radius: 100%;
    &:hover {
        background: $color--primary;
        color: $txt-color--white;
    }
}
.menu-dropdown-action {
    border: 1px solid #d9dbe9;
    box-shadow: 0 6px 10px #eff0f6;
    border-radius: 4px;
    .ant-dropdown-menu-item {
        padding: 5px;
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        color: #fcb823;
    }
}

.box-shadow {
    box-shadow: 0px 33px 62px rgba(129, 129, 129, 0.06), 0px 13.7866px 25.9021px rgba(129, 129, 129, 0.0431313),
        0px 7.37098px 13.8485px rgba(129, 129, 129, 0.0357664), 0px 4.13211px 7.76336px rgba(129, 129, 129, 0.03),
        0px 2.19453px 4.12306px rgba(129, 129, 129, 0.0242336), 0px 0.913195px 1.7157px rgba(129, 129, 129, 0.0168687);
}

.btn-primary {
    background-color: #1a1a1a;
    border-color: #1a1a1a;
    color: $color--primary;
    box-shadow: 0px 17px 31px rgba(0, 0, 0, 0.11), 0px 7.10219px 12.9511px rgba(0, 0, 0, 0.079074),
        0px 3.79717px 6.92426px rgba(0, 0, 0, 0.0655718), 0px 2.12866px 3.88168px rgba(0, 0, 0, 0.055),
        0px 1.13052px 2.06153px rgba(0, 0, 0, 0.0444282), 0px 0.470434px 0.85785px rgba(0, 0, 0, 0.030926);
    &:hover,
    &:focus,
    &:active {
        background: #424242;
        border-color: #424242;
        box-shadow: unset;
    }
}

.text-link {
    color: $color-tenant--text;
    &:hover,
    &:focus,
    &:active {
        color: $color-tenant--text-hover;
    }
}

strong.pagination-customize {
    color: $color-tenant--btn-active;
}

.text-delete {
    &:hover {
        color: rgb(235, 0, 0);
    }
}

.custom-confirm-modal {
    &.normal {
        .ant-modal-body {
            padding: 16px 20px !important;
        }
   
    }
    .confirm-btn-group {
        margin-top: 24px;
        text-align: right;
    }

    .ant-modal-header {
        border: unset !important;
    }

    .ant-modal-body {
        padding: 42px 36px 30px !important;
    }
    .ant-modal-footer {
        display: none !important;
    }
    .confirm-body {
        color: #000000d9;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.4;
    }
    .anticon-exclamation-circle {
        color: #faad14;
        float: left;
        margin-right: 16px;
        font-size: 22px;
    }
}
.text-quantity {
    color: #999999;
    &:hover {
        color: #bdbaba;
    }
}

.text-color-tenant {
    color: $color-tenant--text;
}

.ant-typography-tooltip {
    color: #fff !important;
    .ant-typography-copy {
        color: #45efd7;
    }
}
.w-content {
    width: fit-content;
}
