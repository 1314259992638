.order-detail {
    color: #444444;
    strong {
        font-weight: 500;
        color: #111111;
    }
}

.order-detail-table {
    table {
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
    }
    .ant-table-thead > tr > th {
        color: #ffffff !important;
        background-color: #59aae4 !important;
    }
}

.order-status {
    position: relative;
    padding-left: 12px;
    &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #ffa200;
    }

    &--FINISH:before {
        background-color: #11d713;
    }

    &--CANCELED:before {
        background-color: #6c757d;
    }
    &--DELIVERED:before {
        background-color: #0048ad;
    }
}

/*------------create order page------------*/

.create-order {
    .title-box {
        color: $color--primary;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
    }

    .create-order-btn {
        background: #078aff;
        border-color: #078aff;
    }
}

.hidden_input {
    .ant-form-item-control-input {
        display: none;
    }
}
.order-detail-tab-package {
    .ant-tabs-tab-btn {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 1.5em;
        text-transform: uppercase !important;
    }
    .ant-tabs-nav::before {
        border-bottom: unset !important;
    }
}
.histories_order {
    .ant-timeline.ant-timeline-label .ant-timeline-item-label {
        width: calc(30% - 12px);
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-tail {
        left: 32%;
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-head {
        left: 32%;
    }
    .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
        left: calc(35% - 4px);
        width: calc(65% - 14px);
        text-align: left;
    }
}

.payment-info-order {
    .ant-timeline {
        margin-top: 25px;
    }
    .ant-timeline-item-last {
        padding-bottom: 0 !important;
    }
}

.ant-tabs.customize-order-ant-tabs {
    overflow: visible !important;
    &.ant-tabs-top > .ant-tabs-nav {
        margin: 0 !important;
        font-weight: 500 !important;
        &::before {
            border: 0 !important;
        }
    }
    .ant-tabs-content-holder {
        box-shadow: 0px 33px 62px rgba(129, 129, 129, 0.06), 0px 13.7866px 25.9021px rgba(129, 129, 129, 0.0431313),
            0px 7.37098px 13.8485px rgba(129, 129, 129, 0.0357664), 0px 4.13211px 7.76336px rgba(129, 129, 129, 0.03),
            0px 2.19453px 4.12306px rgba(129, 129, 129, 0.0242336),
            0px 0.913195px 1.7157px rgba(129, 129, 129, 0.0168687);
        background: #fff;
        padding: 24px;
        border-radius: 12px;
        margin-top: 11px !important;
        
    }
    &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        border: none;
        border-radius: 12px 12px 12px 12px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 10px 24px !important;
        color: $txt-color--label !important;
        background-color: #ffffff;
    }
    &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active.ant-tabs-tab,
    &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active.ant-tabs-tab {
        border: none;
        color: #000000 !important;
        background-color: $color-tenant--btn-active;
    }
}

._importing-document-product-list-total {
    font-size: 13px;
    font-weight: 400 !important;
}

.sales-channel-image {
    width: 26px;
    height: 26px;
    &.lazada {
        width: 22px;
        height: 22px;
    }
}
