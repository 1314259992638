//@import '~antd/dist/antd.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'settings/setting.color';
@import 'custom-antd';
@import 'bootstrap/bootstrap';
@import 'generics/generic.box-sizing';
@import 'generics/generic.normalize';
@import 'generics/generic.reset';
@import 'settings/setting.text';
@import 'settings/setting.border';
@import 'settings/setting.table';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import 'components/layout';
@import 'components/order-detail';
@import 'components/package';
@import 'components/warehouse';
@import 'components/product';
@import 'components/mobile-tab';
@import 'components/drop-down-tab';
@import 'components/stamp';
@import 'common';
@import 'print';
@import 'components/finance';
@import 'components/dashboard';
@import 'components/dropshipping';
@import 'components/transaction';
@import 'components/quotation';
@import 'components/setting';
@import '../font-awesome/all.css'
