@page {
    margin: 0;
}

.sheet {
    margin: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    page-break-after: always;
}

.A3 .sheet {
    width: 297mm;
    height: 419mm;
}

.A3.landscape .sheet {
    width: 420mm;
    height: 296mm;
}

.A4 .sheet {
    width: 210mm;
    height: 296mm;
}

.A4.landscape .sheet {
    width: 297mm;
    height: 209mm;
}

.A5 .sheet {
    width: 148mm;
    height: 209mm;
}

.A5.landscape .sheet {
    width: 210mm;
    height: 147mm;
}

.letter .sheet {
    width: 216mm;
    height: 279mm;
}

.letter.landscape .sheet {
    width: 280mm;
    height: 215mm;
}

.legal .sheet {
    width: 216mm;
    height: 356mm;
}

.legal.landscape .sheet {
    width: 357mm;
    height: 215mm;
}

.sheet.padding-10mm {
    padding: 10mm;
}

.sheet.padding-15mm {
    padding: 15mm;
}

.sheet.padding-20mm {
    padding: 20mm;
}

.sheet.padding-25mm {
    padding: 25mm;
}

.sheet.padding-50mm {
    padding: 50mm;
}

@media screen {
    .sheet {
        background: white;
        box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
        margin: 5mm auto;
    }
}

@media print {
    .A3.landscape {
        width: 420mm;
    }
    .A3,
    .A4.landscape {
        width: 297mm;
    }
    .A4,
    .A5.landscape {
        width: 210mm;
    }
    .A5 {
        width: 148mm;
    }
    .letter,
    .legal {
        width: 216mm;
    }
    .letter.landscape {
        width: 280mm;
    }
    .legal.landscape {
        width: 357mm;
    }
    .sku-info {
        width: 104mm;
    }
}

.page-break,
.break-before,
.page-break-before {
    page-break-before: always;
}

.break-after,
.page-break-after {
    page-break-after: always;
}

.avoid-break-inside {
    page-break-inside: avoid;
}
