.finance_order {
    .dashboard_stats {
        min-height: 120px !important;
    }
    .finance_stats,
    .dashboard_stats {
        //height: 90px;
        padding: 1.2em;
        background-color: #fff;
        display: flex;
        justify-content: start;
        color: #707070;
        border-radius: 8px;
        box-shadow: 0 33px 62px rgba(129, 129, 129, 0.06), 0 13.7866px 25.9021px rgba(129, 129, 129, 0.0431313),
            0 7.37098px 13.8485px rgba(129, 129, 129, 0.0357664), 0 4.13211px 7.76336px rgba(129, 129, 129, 0.03),
            0 2.19453px 4.12306px rgba(129, 129, 129, 0.0242336), 0 0.913195px 1.7157px rgba(129, 129, 129, 0.0168687);
        .stats_amount {
            min-height: 30px;
            margin-top: 8px;
            color: $color-tenant--text;
            font-size: 20px;
            line-height: 28px;
            b {
                font-size: 16px;
            }
        }
    }
    .finance_stats-vela {
        padding: 24px;

        @include media-breakpoint-down(xs) {
            padding: 18px;
        }

        &.pd-34 {
            padding: 34px 24px;
            @include media-breakpoint-down(xs) {
                padding: 18px;
            }
        }

        .stats_amount {
            font-size: 24px;
            font-weight: 500;
            flex-wrap: wrap;

            @include media-breakpoint-down(xs) {
                font-size: 18px;
            }
            @media (max-width: 360px) {
                font-size: 14px;
            }
        }

        .box-circle {
            padding: 20px 17px;
            border-radius: 50%;
            background-color: $color-tenant--bg-color-light;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;

            @include media-breakpoint-down(xs) {
                display: none;
            }

            @include media-breakpoint-up(lg) {
                margin-right: 32px;
            }

            svg {
                @include media-breakpoint-down(xs) {
                    width: 18px;
                    height: 18px;
                }
                fill: $color-tenant--text;
            }
        }

        .text-box-small {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            margin-top: 8px;
            color: #707070;
            width: 100%;
            @include media-breakpoint-down(xs) {
                font-size: 13px;
                margin: 0;
            }
        }
    }
}
