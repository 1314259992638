.ant-table-thead > tr > th {
	color: #6E7191 !important;
	background: transparent;
	border-bottom: 1px solid #A0A3BD;
    white-space: nowrap;
}

.ant-table-tbody .ant-table-row {
	td {
		border-bottom: 1px solid #e5e7f1;
	}
	&:hover {
		td {
			background-color: #f6fbff;
		}
	}
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
	padding: 15px 10px !important;
}
.ant-table-thead th.ant-table-column-has-sorters {
	padding: 0 !important;
}
.ant-table-column-sorters {
	padding: 15px 10px !important;
}

tr.ant-table-measure-row > td {
	padding: 0 !important;
}

.dynamic-table {
    &-menu {
        width: 24rem;
        padding: 12px 16px;
    }
}
