._btn-accept_modal {
    &:focus {
        background: $color-tenant--btn-active;
        border-color: $color-tenant--btn-border;
    }
    &:hover {
        background: $color-tenant--btn-active-hover;
        border-color: $color-tenant--btn-active-hover;
    }
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after{
    background-color: $color-tenant--btn-active;
}