.setting-chanel {
    .ant-modal-body {
        padding: 2px 22px 25px !important;
    }
    .ant-modal-header {
        padding-bottom: 14px !important;
    }
    &__checkbox {
        .ant-checkbox-wrapper {
            margin: unset;
            margin-bottom: 0.5rem;
        }
        & .ant-checkbox-inner {
            box-shadow: 0px 0px 0px 2px #ddf0ff;
            border: 1px solid #80c8ff;
        }
        .ant-checkbox-input {
            &:focus {
                + .ant-checkbox-inner {
                    border-color: #80c8ff !important;
                }
            }
        }
        & + div {
            color: $txt-color-secondary;
            margin-left: 0.5rem;
            p {
                color: $txt-color-title;
                font-weight: 400;
            }
        }
    }
    &__select {
        margin-left: 1.5rem;
        > p {
            margin: 0.5rem 0;
        }
        .ant-select {
            width: 100%;
        }
        .ant-select-selection-item {
            color: $txt-color-secondary;
        }
    }
    .confirm-btn-group {
        margin-top: 1rem;
    }
}
