$color--primary: #FFFFFF;
$color--background: #FCB823;
$color--secondary: #EAEEFF;
$color--border:#d8d8d8;
$color--font-primary:#333333;
$color--red-c:#ce3c32;
$color--gray-9:#999999;
$color--warning:#ffc107;
$color--green:#0780FF;
$color--boder-cp:rgba(242, 242, 242, 1);;
$color-blue-6:#0066FF;
$color--green-0:#006600;
$color-gray-c:#CCCCCC;
$bg-color--white: #ffffff;
$bg-color--grayf2: #f2f3f8;
$bg-color--grayf22: #f2f2f2;
$bg-color--grayf9: #f9faff;
$bg-color--grayc:#CCCCCC;
$bg-color--black1: #1A1A1A;

$color-button-primary: #0050B3;
$color-input-primary: #0050B3;

$txt-color-primary:#CC8700;
$txt-color-primary-hover: #FCB823;
$txt-color--gray3: #333333;
$txt-color--gray4: #444444;
$txt-color--gray5: #555555;
$txt-color--gray6: #666666;
$txt-color--gray7: #777777;
$txt-color--gray8: #888888;
$txt-color--gray9: #999999;
$txt-color--white: #ffffff;
$txt-color--blue0: #0066FF;
$txt-color--grayc: #cccccc;
$txt-color--redc: #ce3c32;
$txt-color--yellow: #ffc107;
$txt-color--green0: #006600;
$txt-color--green8: #008800;
$txt-color--gray49: #495057;
$txt-color--redf: #ff0000;
$txt-color--label: #6E7191;
$txt-color-title: #1A1A1A;
$txt-color-secondary: #707070;

$border-color--grayd8: #d8d8d8;
$border-color--blue8: #80bdff;
$border-color--redf: #ff2725;
$border-color--gray9: #999999;
$border-color--grayf2f2: #f2f2f2;

$color--new-status: #349700;
$color--received-status: #0780FF;
$color--pending-status: #f27b00;
$color--in_progress-status: #b83700;
$color--completed-status: #25b8b4;
$color--closed-status: rgb(89, 89, 89);

//thay doi theo domain
:root {
  --background-color: #FCB823;

  --text-active: #CC8700;
  --text-hover: #FCB823;

  --menu-active: #CC8700;
  --menu-hover: #FCB823;

  --btn-active: #F9A400;
  --btn-hover-color: #FCB823;

  --link-color: #CC8700;
  --link-hover-color: #FCB823;

  --bg-color-light:#f9f1e0;
}

$color-tenant--background: var(--background-color);

$color-tenant--text: var(--text-active);
$color-tenant--text-hover: var(--text-hover);

$color-tenant--btn-active: var(--btn-active);
$color-tenant--btn-border: var(--btn-active);
$color-tenant--btn-active-hover: var(--btn-hover-color);

$color-tenant--btn-menu-active: var(--menu-active);
$color-tenant--btn-menu-border: var(--menu-active);
$color-tenant--btn-menu-active-hover: var(--menu-hover);

$color-tenant--btn-link: var(--link-color);
$color-tenant--btn-link-hover: var(--link-hover-color);

$color-tenant--bg-color-light: var(--bg-color-light);


